import React from 'react';
import axios from 'axios';
import config from './config';
import _ from 'lodash';
import {Modal, Row, Col, Spin, Button} from 'antd';
import 'antd/dist/antd.css';
import RegisterForm from './RegisterForm.jsx'

import captchaT from './captcha/T.png';
import captchaN from './captcha/N.png';
import captchaM from './captcha/M.png';
import captchaR from './captcha/R.png';
import captchaL from './captcha/L.png';
import captchaSH from './captcha/SH.png';
import captchaK from './captcha/K.png';
import captchaF from './captcha/F.png';
import captchaB from './captcha/B.png';
import captchaPlus from './captcha/plus.png';
import captchaMinus from './captcha/minus.png';
import captchaTimes from './captcha/times.png';
import captchaEquals from './captcha/equals.png';

const priceDataRoute = `${config.apiUrl}/api/v1/price-info?activeOnly=true&availableOnly=true`;
const registerRoute = `${config.apiUrl}/api/v1/register`;
const registrationStatusRoute = `/registration-status`;
const btcPriceRoute = `${config.apiUrl}/api/v1/btc-price`;
const btcAddressCountRoute = `${config.apiUrl}/api/v1/get-crypto-address-count/BTC`;

const emailRe = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

const qtyChoicesArray = (remaining) => {
    let qtyChoices = [
        { value: '1', displayText: '1' },
        { value: '2', displayText: '2' },
        { value: '3', displayText: '3' },
        { value: '4', displayText: '4' },
        { value: '5', displayText: '5' },
        { value: '6', displayText: '6' },
        { value: '7', displayText: '7' },
        { value: '8', displayText: '8' },
        { value: '9', displayText: '9' }
    ];
    if (remaining || remaining === 0) {
        qtyChoices = qtyChoices.slice(0, remaining);
    }
    return qtyChoices;
}

const captchaImage = {
    1: captchaT,
    2: captchaN,
    3: captchaM,
    4: captchaR,
    5: captchaL,
    6: captchaSH,
    7: captchaK,
    8: captchaF,
    9: captchaB
};

const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
};

const makeItemOrder = priceData => {
    let itemOrder = [];
    priceData.results.forEach(item => {
        itemOrder.push(item.key)
    });
    return itemOrder;
};

const makeItemData = priceData => {
    let itemData = {};
    let qtyChoices;
    priceData.results.forEach(item => {
        // Information about the item.
        let itemDatum = {
            itemName: item.name,
            itemKey: item.key,
            description: item.description,
            quantityUnit: item.quantityUnit,
            durationUnit: item.durationUnit,
            qtyOptions: [{ key: `${item.key}-qty-0`, value: '0', displayText: ' ' }],
            durationOptions: [{ key: `${item.key}-duration-0`, value: '0', price: 0.0, displayText: ' ' }],
            currentQty: 0,
            currentDuration: 0,
            extendedPrice: 0.0
        };

        // Options for the quantity drop down.
        qtyChoices = qtyChoicesArray(item.quantityRemaining);
        qtyChoices.forEach(choice => {
            itemDatum.qtyOptions.push({
                key: `${item.key}-qty-${choice.value}`,
                value: choice.value,
                displayText: choice.value === '0' ? ' ' : `${choice.displayText} ${item.quantityUnit}`
            })
        });

        // Options for the duration drop down.
        let durations = _.keys(item.durations);
        durations.forEach(duration => {
            let price = parseFloat(item.durations[duration][0].price);
            itemDatum.durationOptions.push({
                key: `${item.key}-duration-${duration}`,
                value: duration,
                price: price,
                displayText: `${duration} ${item.durationUnit} ($${price})`
            })
        });

        itemData[item.key] = itemDatum;
    });
    return itemData;
};

const makeCaptcha = () => {
    let operator = ['plus', 'minus', 'times'][getRandomIntInclusive(0, 2)];
    let firstNum = null;
    let secondNum = null;
    let answer = null;
    let operatorImage;
    if (operator === 'minus') {
        firstNum = getRandomIntInclusive(6, 9);
        secondNum = getRandomIntInclusive(1, firstNum);
        answer = firstNum - secondNum;
        operatorImage = captchaMinus;
    } else if (operator === 'plus') {
        firstNum = getRandomIntInclusive(1, 9);
        secondNum = getRandomIntInclusive(1, 9);
        answer = firstNum + secondNum;
        operatorImage = captchaPlus;
    } else {
        firstNum = getRandomIntInclusive(1, 9);
        secondNum = getRandomIntInclusive(1, 9);
        answer = firstNum * secondNum;
        operatorImage = captchaTimes;
    }

    let firstNumImage = captchaImage[firstNum];
    let secondNumImage = captchaImage[secondNum];
    return {
        firstOperand: firstNumImage,
        operator: operatorImage,
        secondOperand: secondNumImage,
        equalSign: captchaEquals,
        answer: answer
    }
};

const makePriceText = (totalPrice, currency) => {
    if (totalPrice === 0) {
        return {BTC: '', USD: ''};
    }

    return {
        BTC: `($${(totalPrice * config.btcFactor).toFixed(2)} total, in BTC)`,
        USD: `($${totalPrice} total)`
    }
};

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: null,
            otherNames: '',
            email: null,
            joinEmailList: false,
            comment: '',
            priceData: null,
            captcha: {},
            captchaAnswer: null,
            itemOrder: null,
            itemData: null,
            btcAddressCount: 0,
            currentBTCPrice: null,
            btcPriceAsOf: null,
            currency: 'USD',
            priceText: makePriceText(0, 'USD'),
            submitButtonDisabled: true,
            isSubmitted: false,
            showErrorDialog: false,
            errorText: '',
            totalPrice: 0,
        };

        this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    handleInputChange(field, value) {
        let newState = {};
        newState[field] = value;
        this.setState(newState, this.updateSubmitButtonStatus);
    }

    handleCheckboxChange(field, checked) {
        let newState = {};
        newState[field] = checked;
        this.setState(newState);
    }

    handleSelectChange(itemKey, changeType, value) {
        let itemData = this.state.itemData;

        // Update the current value of the quantity or duration.
        if (changeType === 'qty') {
            itemData[itemKey].currentQty = parseInt(value)
        } else if (changeType === 'duration') {
            itemData[itemKey].currentDuration = parseInt(value)
        }

        // Update the extended prices.
        let price = _.find(itemData[itemKey].durationOptions, {value: itemData[itemKey].currentDuration.toString()}).price;
        itemData[itemKey].extendedPrice = parseFloat(itemData[itemKey].currentQty) * parseFloat(price);

        // Update the total price.
        let totalPrice = 0.0;
        _.values(itemData).forEach(item => {
            totalPrice += item.extendedPrice;
        });

        // Update the state.
        this.setState({
            itemData: itemData,
            totalPrice: totalPrice,
            priceText: makePriceText(totalPrice, this.state.currency)
        }, this.updateSubmitButtonStatus)
    }

    handleCurrencyChange(value) {
        this.setState({
            currency: value,
            priceText: makePriceText(this.state.totalPrice, value)
        })
    }

    updateSubmitButtonStatus() {
        let submitButtonDisabled = true;
        if (this.state.fullName &&
            this.state.email && this.state.email.match(emailRe) &&
            this.state.totalPrice > 0 &&
            this.state.captcha.answer === parseInt(this.state.captchaAnswer, 10)) {
            submitButtonDisabled = false;
        }
        this.setState({
            submitButtonDisabled: submitButtonDisabled
        })
    }

    handleError(error) {
        // Handles an error by displaying a dialog with information from the error object.
        let errorText;
        if (error.response) {
            errorText = `Status ${error.response.status}, ${error.response.statusText}: ${JSON.stringify(error.response.data)}`
        } else {
            errorText = JSON.stringify(error)
        }
        this.setState({
            showErrorDialog: true,
            errorText: errorText,
        })
    }
    handleCloseErrorDialog() {
        this.setState({
            showErrorDialog: false,
            errorText: ''
        })
    }

    handleSubmit() {
        this.setState({
            submitButtonDisabled: true,
            isSubmitted: true
        });

        let currency = this.state.currency;

        // Create the payload.
        let totalPrice = this.state.totalPrice;
        let totalBTCPrice = '0';
        if (currency === 'BTC') {
            totalPrice = totalPrice * config.btcFactor;
            totalBTCPrice = (totalPrice / this.state.currentBTCPrice).toFixed(8);
        }
        let data = {
            fullName: this.state.fullName,
            otherNames: this.state.otherNames,
            email: this.state.email,
            joinEmailList: this.state.joinEmailList,
            comment: this.state.comment,
            totalPrice: totalPrice,
            totalBTCPrice: totalBTCPrice,
            exchangeRate: this.state.currentBTCPrice,
            currency: currency,
            items: []
        };
        _.keys(this.state.itemData).forEach(key => {
            let itemDatum = this.state.itemData[key];
            if (itemDatum.currentQty > 0 && itemDatum.currentDuration > 0) {
                let item = {
                    key: key,
                    quantity: itemDatum.currentQty,
                    duration: itemDatum.currentDuration,
                    extendedPrice: itemDatum.extendedPrice
                };
                data.items.push(item)
            }
        });

        // Post the data.
        axios({
            method: 'post',
            url: registerRoute,
            data: data
        })
            .then(response => {
                window.location.href = `${registrationStatusRoute}/${response.data.registrantKey}`;
            })
            .catch(error => {
                this.handleError(error)
            });
    }

    componentDidMount() {
        // Fetch the price data.
        axios({
            method: 'get',
            url: priceDataRoute
        })
            .then(response => {
                let priceData = response.data;
                let itemOrder = makeItemOrder(priceData);
                let itemData = makeItemData(priceData);
                this.setState({
                    priceData: priceData,
                    itemOrder: itemOrder,
                    itemData: itemData
                })
            })
            .catch(error => {
                this.handleError(error)
            });

        // Fetch the current price of Bitcoin.
        axios({
            method: 'get',
            url: btcPriceRoute
        })
            .then(response => {
                this.setState({
                    currentBTCPrice: parseFloat(response.data.price),
                    btcPriceAsOf: response.data.datetime
                })
            })
            .catch(error => {
                this.handleError(error)
            });

        // Fetch availability of Bitcoin addresses in the database.
        axios({
            method: 'get',
            url: btcAddressCountRoute
        })
            .then(response => {
                this.setState({
                    btcAddressCount: response.data.count
                })
            })
            .catch(error => {
                this.handleError(error)
            });

        // Create CAPTCHA.
        this.setState({
            captcha: makeCaptcha()
        });
    }

    render() {
        let rvMessage = (<div></div>);
        let cabinMessage = (<div></div>);
        let formArea = (<p>Fetching price information ...</p>);
        if (this.state.itemData && this.state.itemOrder) {
            // Price data has been fetched and parsed.
            if ('rv-electricity' in this.state.itemData) {
                rvMessage = (
                    <p>Hookups for RV electricity are limited. If you want one, please provide the information below,
                        click the <strong>Checkout</strong> button and pay. (If you get RV electricity, you must still register for camping.)</p>
                );
            }
            if ('cabin-tent' in this.state.itemData) {
                cabinMessage = (
                    <p>Learn about <a href="https://mplfest.org/index.php/lodging/#cabin-tents" rel="noopener noreferrer" target="_blank">Cabin Tents</a> on the Fest website! (If you get a cabin-tent, you must still register for camping.)</p>
                );
            }

            formArea = (
                <RegisterForm
                    captcha={this.state.captcha}
                    itemData={this.state.itemData}
                    itemOrder={this.state.itemOrder}
                    currency={this.state.currency}
                    priceText={this.state.priceText}
                    submitButtonDisabled={this.state.submitButtonDisabled}
                    btcAddressCount={this.state.btcAddressCount}
                    currentBTCPrice={this.state.currentBTCPrice}
                    isSubmitted={this.state.isSubmitted}
                    handleInputChange={this.handleInputChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSelectChange={this.handleSelectChange}
                    handleCurrencyChange={this.handleCurrencyChange}
                    handleSubmit={this.handleSubmit}
                    handleError={this.handleError}
                />
            )
        }

        let btcPriceInfo = (<p>Retrieving current BTC price ...</p>);
        if (this.state.currentBTCPrice) {
            if (this.state.btcAddressCount > 0) {
                let asOf = `as of ${this.state.btcPriceAsOf}`;
                btcPriceInfo = (
                    <p data-toggle="tooltip" data-placement="right" title={asOf}>
                        1 BTC = { this.state.currentBTCPrice.toLocaleString('en', {style: 'currency', currency: 'USD'}) }
                    </p>
                )
            } else {
                btcPriceInfo = (
                    <p>
                        There are no BTC addresses available in the registration database.
                        If you would like to pay with Bitcoin, please try again later.
                    </p>)
            }
        }

        let footerArea;
        if (this.state.isSubmitted) {
            footerArea = (
                <Spin size='large' style={{marginTop: 0, marginBottom: '20px' }} />
            )
        } else {
            footerArea = (
                <div>{ btcPriceInfo }</div>
            )
        }

        let errorModalTitle = (
            <div style={{color: 'red'}}>Error</div>
        )

        return (
            <div>
                <Modal
                    title={errorModalTitle}
                    visible={this.state.showErrorDialog}
                    onOK={this.handleCloseErrorDialog}
                    onCancel={this.handleCloseErrorDialog}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.handleCloseErrorDialog}>OK</Button>
                    ]}
                >
                    <p>An error occurred:</p>
                    <p>{ this.state.errorText }</p>
                </Modal>

                <h2>Register for the Fest</h2>
                <p>If you plan to pay with cash when you arrive at the Fest, you don't need fill out this form.</p>
                { cabinMessage }{ rvMessage }
                <Row>
                    <Col span={24}>
                        { formArea }
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        { footerArea }
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Register;
