import React from 'react'
import { Form, Row, Col, Input, Button, Select, Space, Switch } from 'antd';
import 'antd/dist/antd.css';
import config from "./config";

const { Option } = Select;
const emailRe = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

function RegisterForm (props) {
    const handleInputChange = (e) => {
        props.handleInputChange(e.target.id, e.target.value);
    }

    const handleJoinEmailListChange = (e) => {
        props.handleCheckboxChange('joinEmailList', e);
    }

    const handleSelectChange = (value, option) => {
        let targetId = option.key;
        let changeType = null;
        let itemKey = null;
        if (targetId.indexOf('-qty') >= 0) {
            changeType = 'qty';
            itemKey = targetId.substr(0, targetId.indexOf('-qty'))
        }
        else if (targetId.indexOf('-duration') >= 0) {
            changeType = 'duration';
            itemKey = targetId.substr(0, targetId.indexOf('-duration'))
        }

        props.handleSelectChange(itemKey, changeType, value);
    }

    const handleCurrencyChange = (value) => {
        props.handleCurrencyChange(value);
    }

    const onSubmit = () => {
        props.handleSubmit();
    }

    const onFormError = errorInfo => {
        props.handleError(JSON.stringify(errorInfo));
    }

    const itemRows = () => {
        let itemData = props.itemData;

        // Create a row for each item.
        let rows = [];
        props.itemOrder.forEach(key => {
            // Create the options for the quantity drop down for this item.
            let qtyOptions = [];
            itemData[key].qtyOptions.forEach(choice => {
                qtyOptions.push(
                    <Option key={choice.key} value={choice.value}>
                        { choice.displayText }
                    </Option>
                )
            });

            // Crete the options for the duration drop down for this item.
            let durationOptions=[];
            itemData[key].durationOptions.forEach(choice => {
                durationOptions.push(
                    <Option key={choice.key} value={choice.value}>
                        { choice.displayText }
                    </Option>
                )
            });

            // Display the extended price if it's greater than 0.
            let extendedPriceText = '';
            if (itemData[key].extendedPrice > 0) {
                extendedPriceText = `= $${itemData[key].extendedPrice}`
            }

            let row = (
                <div key={key}>
                    <Form.Item label={itemData[key].itemName}>
                        <Input.Group>
                            <Form.Item name={`${itemData[key].itemKey}-qty`} noStyle>
                                <Select
                                    id={`${key}-qty`}
                                    onChange={handleSelectChange}
                                    style={{ width: '40%' }}
                                >
                                    { qtyOptions }
                                </Select>
                            </Form.Item>
                            <span style={{ paddingLeft: '1em', paddingRight: '1em' }}>for</span>
                            <Form.Item name={`${itemData[key].itemKey}-duration`} noStyle>
                                <Select
                                    id={`${key}-duration`}
                                    onChange={handleSelectChange}
                                    style={{ width: '40%' }}
                                >
                                    { durationOptions }
                                </Select>
                            </Form.Item>
                            <span style={{ paddingLeft: '1em' }}>{ extendedPriceText }</span>
                        </Input.Group>
                    </Form.Item>
                    <hr className='visible-xs' style={{ marginBottom: '20px' }} />
                </div>
            );
            rows.push(row)
        });

        return rows;
    }

    let registerButtonStyle = props.isSubmitted ? { display: 'none' } : { display: 'block' };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
        },
    };
    const tailLayout = {
        wrapperCol: { span: 24 },
    };

    return (
        <Form
            {...formItemLayout}
            onFinish={onSubmit}
            onFinishFailed={onFormError}
        >
            <Form.Item
                label='Full Name'
                name='fullName'
                rules={[{ required: true, message: 'Your name is required' }]}
                onChange={handleInputChange}
            >
                <Input placeholder='Enter your name' />
            </Form.Item>

            <Form.Item
                label='Others Attending'
                name='otherNames'
                onChange={handleInputChange}
            >
                <Input placeholder='The names of other people in your party &mdash; this speeds up the check-in process' />
            </Form.Item>

            <Form.Item
                label='Email'
                name='email'
                onChange={handleInputChange}
                rules={[
                    { required: true, message: 'Your email address is required' },
                    { validator: (__, value) => {
                            if (!value || value.match(emailRe)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Invalid email address');
                            }
                        }
                    }
                ]}
            >
                <Input placeholder='Enter your email address' />
            </Form.Item>

            <Form.Item label='Join Email List'>
                <Space align={"baseline"}>
                    <Form.Item
                        name='joinEmailList'
                    >
                        <Switch onChange={handleJoinEmailListChange} />
                    </Form.Item>
                    <span>Join our email list to keep up to date on all things MPLFest!</span>
                </Space>
            </Form.Item>

            <Form.Item
                label='Comments'
                name='comment'
                onChange={handleInputChange}
            >
                <Input.TextArea placeholder='Interested in volunteering? Giving a presentation? Will you be vending food or items? Let us know!' />
            </Form.Item>

            { itemRows() }

            <Form.Item
                label='Currency'
                name='currency'
                initialValue={props.currency}
            >
                <Select id='currency' name='currency' onChange={handleCurrencyChange}>
                    <Option key='currency-usd' value='USD'>USD { props.priceText.USD }</Option>
                    <Option
                        key='currency-btc'
                        value='BTC'
                        disabled={!props.currentBTCPrice || !props.btcAddressCount}
                    >
                        BTC { props.priceText.BTC }
                    </Option>
                </Select>
            </Form.Item>

            <Form.Item
                label='Solve this'
                name='captchaAnswer'
                rules={[
                    { required: true, message:'You must provide an answer'},
                    { validator: (__, value) => {
                            if (!value || props.captcha.answer === parseInt(value, 10)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Incorrect answer');
                            }
                        }
                    }
                ]}
            >
                <Row>
                    <Col>
                        <img alt="math symbol" className='captcha' src={props.captcha.firstOperand} />
                        <img alt="math symbol" className='captcha' src={props.captcha.operator} />
                        <img alt="math symbol" className='captcha' src={props.captcha.secondOperand} />
                        <img alt="math symbol" className='captcha' src={props.captcha.equalSign} />
                    </Col>
                    <Col>
                        <Input id='captchaAnswer' onChange={handleInputChange} placeholder='answer'/>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item {...tailLayout} shouldUpdate={true} style={registerButtonStyle}>
                {() => (
                    <Button
                        htmlType='submit'
                        type='primary'
                        disabled={ props.submitButtonDisabled }
                        style={{marginTop: '1em'}}
                    >
                        Checkout
                    </Button>
                )}
            </Form.Item>
        </Form>
    )
}

export default RegisterForm;
