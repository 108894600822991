import _ from 'lodash';

const envConfig = {
    base: {
        appUrl: 'http://localhost:3000',
        apiUrl: 'http://localhost:5001',
        mplfestUrl: 'https://www.mplfest.org',
        subscribeUrl: 'https://mailchi.mp/54f9d688db57/mplfest',
        festShirtUrl: 'https://mplfest.org/index.php/fest-shirts/',
        stripePublicKey: 'pk_test_hFwdSai4ByqYtIjBbqbQkRxy',
        btcFactor: 0.9,
        btcDiscount: '10%'
    },
    production: {
        appUrl: 'https://app.mplfestpay.org',
        apiUrl: 'https://api.mplfestpay.org',
        stripePublicKey: 'pk_live_qiEMEguqi17NJKFfGSdA3QOM'
    }
}
let config = _.extend(envConfig.base, envConfig[process.env.NODE_ENV]);

export default config;
