import React from "react";
import axios from "axios";
import _ from 'lodash';
import config from './config';
import {Button, Modal, Table} from 'antd';
import 'antd/dist/antd.css';

const priceInfoRoute = `${config.apiUrl}/api/v1/price-info`;
const itemPeriodsRoute = `${config.apiUrl}/api/v1/item-periods`;
const registrationSystemStatusRoute = `${config.apiUrl}/api/v1/registration-system-status`;

class Prices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priceInfo: [],
            itemPeriods: [],
            showErrorDialog: false,
            errorText: '',
            priceListLevel: ''
        }

        this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
    }

    handleError(error) {
        // Handles an error by displaying a dialog with information from the error object.
        let errorText;
        if (error.response) {
            errorText = `Status ${error.response.status}, ${error.response.statusText}: ${JSON.stringify(error.response.data)}`
        } else {
            errorText = JSON.stringify(error)
        }
        this.setState({
            showErrorDialog: true,
            errorText: errorText,
        })
    }

    handleCloseErrorDialog() {
        this.setState({
            showErrorDialog: false,
            errorText: ''
        })
    }

    componentDidMount() {
        const priceInfoRequest = axios.get(priceInfoRoute);
        const itemPeriodsRequest = axios.get(itemPeriodsRoute);
        const registrationSystemStatusRequest = axios.get(registrationSystemStatusRoute);
        axios
            .all([priceInfoRequest, itemPeriodsRequest, registrationSystemStatusRequest])
            .then(axios.spread((...responses) => {
                this.setState({
                    priceInfo: responses[0].data.results,
                    itemPeriods: responses[1].data.results,
                    priceListLevel: responses[2].data.priceListLevel
                })
            }))
            .catch(error => {
                this.handleError(error)
            });
    }

    render() {
        let errorModalTitle = (
            <div style={{color: 'red'}}>Error</div>
        )

        return (
            <div>
                <Modal
                    title={errorModalTitle}
                    visible={this.state.showErrorDialog}
                    onOK={this.handleCloseErrorDialog}
                    onCancel={this.handleCloseErrorDialog}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.handleCloseErrorDialog}>OK</Button>
                    ]}
                >
                    <p>An error occurred:</p>
                    <p>{ this.state.errorText }</p>
                </Modal>

                <h2>Prices</h2>
                <p>Learn about <a href="https://mplfest.org/index.php/lodging/#cabin-tents" rel="noopener noreferrer" target="_blank">Cabin Tents</a> on the Fest website!</p>
                <p>Save {config.btcDiscount} when you pay with Bitcoin. If you’d like to pay with a different currency, please contact us at: <a href="mailto:mplfest@gmail.com">mplfest@gmail.com</a>.</p>
                {this.state.priceListLevel === 'detail' &&
                    <PriceTables
                        priceInfo={this.state.priceInfo}
                        itemPeriods={this.state.itemPeriods}
                    />
                }
                {this.state.priceListLevel === 'summary' &&
                    <PriceSummaryTables
                        priceInfo={this.state.priceInfo}
                        itemPeriods={this.state.itemPeriods}
                    />
                }
            </div>
        )
    }
}

class PriceTables extends React.Component {
    render() {
        let items = this.props.priceInfo;
        let periods = this.props.itemPeriods;

        let itemInfo = [];
        items.forEach(item => {
            // Create the column info.
            let columns = [{
                title: item.durationUnit,
                dataIndex: 'duration',
                key: 'duration',
                width: '12em'
            }];
            if (periods.length === 1) {
                columns.push({
                    title: 'Price',
                    dataIndex: 'period-1',
                    key: 'period-1'
                });
            } else {
                let n = 1
                periods.forEach(period => {
                    columns.push({
                        title: period.description,
                        dataIndex: `period-${n}`,
                        key: `period-${n}`
                    });
                    n += 1;
                });
            }

            // Create the data.
            let dataSource = [];
            _.keys(item.durations).forEach(key => {
                let dataSourceRow = {
                    key: key,
                    duration: key
                }

                let n = 1
                item.durations[key].forEach(itemInfo => {
                    dataSourceRow[`period-${n}`] = itemInfo.price;
                    n += 1;
                });

                dataSource.push(dataSourceRow);
            });

            // Create the info for this item, with a heading, description and table.
            let itemDescription = (
                <p><em>{ item.description }</em></p>
            );
            if (item.quantityLimit && item.quantityRemaining) {
                itemDescription = (
                    <p><em>{ item.description } ({ item.quantityRemaining } of { item.quantityLimit } remaining.)</em></p>
                )
            }
            if (!item.available) {
                itemDescription = (
                    <p><em>{ item.description } (This item is no longer available)</em></p>
                )
            }
            let itemDetail = (
                <div key={ item.id } >
                    <h3>{ item.name }</h3>
                    { itemDescription }
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        size="middle"
                        style={{marginBottom: '2em'}}
                    />
                </div>
            );

            // Include the info for this item in the array to be rendered.
            itemInfo.push(itemDetail);
        });

        return (
            <div>{ itemInfo }</div>
        )
    }
}

class PriceSummaryTables extends React.Component {
    render() {
        let items = this.props.priceInfo;
        let periods = this.props.itemPeriods;

        let itemInfo = [];
        items.forEach(item => {
            // Create the column info.
            let columns = [{
                title: '',
                dataIndex: 'duration',
                key: 'duration',
                width: '12em'
            }];
            if (periods.length === 1) {
                columns.push({
                    title: 'Price',
                    dataIndex: 'period-1',
                    key: 'period-1'
                });
            } else {
                let n = 1
                periods.forEach(period => {
                    columns.push({
                        title: period.description,
                        dataIndex: `period-${n}`,
                        key: `period-${n}`
                    });
                    n += 1;
                });
            }

            // Create the data.
            let dataSourceRow = {
                key: 1,
                duration: `Price per ${item.durationUnit.toLowerCase().replace('(s)','')}`
            }

            let n = 1
            item.durations[1].forEach(itemInfo => {
                dataSourceRow[`period-${n}`] = itemInfo.price;
                n += 1;
            });

            let dataSource = [dataSourceRow]

            // Create the info for this item, with a heading, description and table.
            let itemDescription = (
                <p><em>{ item.description }</em></p>
            );
            if (item.quantityLimit && item.quantityRemaining) {
                itemDescription = (
                    <p><em>{ item.description } ({ item.quantityRemaining } of { item.quantityLimit } remaining.)</em></p>
                )
            }
            if (!item.available) {
                itemDescription = (
                    <p><em>{ item.description } (This item is no longer available)</em></p>
                )
            }
            let itemDetail = (
                <div key={ item.id } >
                    <h3>{ item.name }</h3>
                    { itemDescription }
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        size="middle"
                        style={{marginBottom: '2em'}}
                    />
                </div>
            );

            // Include the info for this item in the array to be rendered.
            itemInfo.push(itemDetail);
        });

        return (
            <div>{ itemInfo }</div>
        )
    }
}

export default Prices;
