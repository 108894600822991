import React from 'react';
import Register from './Register.jsx';
import Prices from './Prices.jsx';
import RegistrationStatus from './RegistrationStatus.jsx'
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from "react-router-dom";
import { Layout, Menu } from 'antd';
import axios from 'axios';
import config from "./config";
import 'antd/dist/antd.css';
import './index.css';

const registrationSystemStatusRoute = `${config.apiUrl}/api/v1/registration-system-status`;

const menuIndex = {
    '/': '1',
    '/prices': '2'
};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationStatus: undefined
        }
    }

    componentDidMount() {
        axios
            .get(registrationSystemStatusRoute)
            .then(response => {
                this.setState({
                    registrationSystemStatus: response.data.registrationSystemStatus
                })
            });
    }

    render() {
        return (
            <Router>
                <h1 className="app-heading">MPLFest Payment System</h1>
                <LayoutComponentWithRouter
                    registrationSystemStatus={this.state.registrationSystemStatus}
                />
            </Router>
        )
    }
}

class LayoutComponent extends React.Component {
    render() {
        const { Header, Content } = Layout;
        const { location } = this.props;

        return (
            <Layout className="layout">
                <Header>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={menuIndex[location.pathname]}
                    >
                        <Menu.Item key="1"><Link to="/">Register</Link></Menu.Item>
                        <Menu.Item key="2"><Link to="/prices">Prices</Link></Menu.Item>
                        <Menu.Item key="3"><a href="https://www.mplfest.org">Return to MPLFest.org</a></Menu.Item>
                    </Menu>
                </Header>
                <Content className="content">
                    <Switch>
                        <Route exact path="/prices" component={Prices} />
                        <Route exact path="/registration-status/:registrantKey" render={(props) => <RegistrationStatus {...props} /> } />
                        <Route exact path="/payment-success/:registrantKey" render={(props) => <PaymentSuccess {...props} /> } />
                        <Route path="/" component={this.props.registrationSystemStatus === 'open' ? Register : RegistrationClosed} />
                    </Switch>
                </Content>
            </Layout>
        )
    }
}

const LayoutComponentWithRouter = withRouter(LayoutComponent);

class RegistrationClosed extends React.Component {
    render() {
        return (
            <div>
                <h2>Registration</h2>
                <p>Registration is closed. Please pay with cash at check-in.</p>
            </div>
        )
    }
}

class PaymentSuccess extends React.Component {
    render() {
        let registrantKey = this.props.match.params.registrantKey;
        return (
            <div>
                <h2>Payment Succeeded</h2>

                <p>Thank you for your payment!</p>

                <p>You can view to your registration information here:&nbsp;
                    <a href={config.appUrl + '/registration-status/' + registrantKey}>{config.appUrl}/registration-status/{registrantKey}</a></p>

                <p>You may also return to the <a href="https://www.mplfest.org">Fest website</a>.</p>
            </div>
        )
    }
}

export default App;
