import React from 'react'
import axios from "axios";
import {Modal, Table, Button} from "antd";
import config from './config';
import {loadStripe} from '@stripe/stripe-js';
import moment from "moment";

const registrantRoute = `${config.apiUrl}/api/v1/registrant`;
const qrCodeRoute = `${config.apiUrl}/api/v1/qrcode`;
const stripePromise = loadStripe(config.stripePublicKey);

class RegistrationStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            registrantKey: this.props.match.params.registrantKey,
            registrationData: null,
            fullName: null,
            currency: null,
            paymentComplete: false,
            showErrorDialog: false,
            altAddress: '',
            errorText: '',
            clientSecret: null,
            paymentButtonDisabled: true,
            showPaymentForm: false
        };

        this.handleError = this.handleError.bind(this);
        this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
        this.handlePaymentError = this.handlePaymentError.bind(this);
    }

    handleError(error) {
        // Handles an error by displaying a dialog with information from the error object.
        let errorText;
        if (error.response) {
            errorText = `Status ${error.response.status}, ${error.response.statusText}: ${JSON.stringify(error.response.data)}`
        } else {
            errorText = JSON.stringify(error)
        }
        this.setState({
            showErrorDialog: true,
            errorText: errorText,
        })
    }

    handleCloseErrorDialog() {
        this.setState({
            showErrorDialog: false,
            errorText: ''
        })
    }

    handlePaymentError(error) {
        this.setState({
            showPaymentForm: false,
            paymentButtonDisabled: false
        }, this.handleError(error));
    }

    componentDidMount() {
        // Fetch the registration status.
        axios({
            method: 'get',
            url: `${registrantRoute}/${this.state.registrantKey}`
        })
            .then(response => {
                let registrationData = response.data.results[0]
                this.setState({
                    registrationData: registrationData,
                    sessionId: registrationData.clientSecret,
                    fullName: registrationData.fullName,
                    currency: registrationData.currency,
                    paymentComplete: registrationData.paymentComplete,
                    paymentStatus: registrationData.paymentStatus,
                    altAddress: registrationData.altAddress,
                    feeAlt: registrationData.feeAlt,
                    feeUsd: registrationData.feeUsd,
                    paymentButtonDisabled: false
                })
            })
            .catch(error => {
                this.handleError(error)
            });
    }

    render() {
        let header = 'Registration Status';

        let summaryColumns = [
            {
                title: '',
                dataIndex: 'field',
                key: 'field',
                width: '15em'
            },
            {
                title: '',
                dataIndex: 'value',
                key: 'value'
            }
        ]

        let detailColumns = [
            {
                title: 'Item',
                dataIndex: 'itemName',
                key: 'itemName'
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration'
            },
            {
                title: 'Subtotal (USD)',
                dataIndex: 'subtotal',
                key: 'subtotal'
            }
        ]

        let paymentArea;
        let summaryDataSource;
        let detailDataSource =[];
        if (this.state.registrationData) {
            if (this.state.paymentComplete) {
                header = 'Payment Complete'
                paymentArea = (
                    <p>Thank you for your payment!</p>
                )
            } else if (this.state.currency === 'BTC') {
                paymentArea = (
                    <div>
                        <p>If you have not done so, please make your payment of <strong>{this.state.feeAlt} BTC</strong> to this address:</p>
                        <p><strong>{this.state.altAddress}</strong></p>
                        <img className='qrcode' alt='BTC QRCode' src={ `${qrCodeRoute}/${this.state.altAddress}?amount=${this.state.feeAlt}&label=Midwest-Peace-and-Liberty-Fest` } />

                        <p>
                            You can check the status of your payment on&nbsp;
                            <a
                                href={'https://blockchain.com/btc/address/' + this.state.altAddress }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                blockchain.com
                            </a>
                        </p>
                    </div>
                )
            } else if (this.state.paymentStatus === 'Cancelled') {
                header = 'Registration Cancelled'
                paymentArea = (
                    <p>Your registration has been cancelled because you did not complete your payment within 24 hours. Please <a href="/">re-register</a>.</p>
                )
            } else {
                header = 'Payment';
                let instructions = (
                    <p>Please click the button below to pay using your credit or debit card. You must pay with 24 hours or you will have to re-register.</p>
                );
                if (this.state.paymentStatus === 'Initiated') {
                    instructions = (
                        <p>
                            <em>It looks you may have already paid or attempted payment. </em>
                            Please click the button below <em>only</em> if you have not paid.
                        </p>
                    )
                }
                paymentArea = (
                    <div>
                        { instructions }
                        <PaymentButton
                            sessionId={this.state.sessionId}
                            registrantKey={this.state.registrantKey}
                            paymentAmount={(this.state.feeUsd).toFixed(2)}
                            handlePaymentError={this.handlePaymentError}
                        />
                    </div>
                )
            }

            let registrationDt = moment(this.state.registrationData.registrationDt);
            summaryDataSource = [
                {
                    key: 0,
                    field: 'Registration number',
                    value: this.state.registrantKey
                },
                {
                    key: 1,
                    field: 'Name',
                    value: this.state.registrationData.name
                },
                {
                    key: 2,
                    field: 'Email',
                    value: this.state.registrationData.email
                },
                {
                    key: 3,
                    field: 'Registration date',
                    value: registrationDt.format("YYYY-MM-DD")
                },
                {
                    key: 4,
                    field: 'Total price (USD)',
                    value: (this.state.registrationData.feeUsd).toFixed(2)
                }
            ];

            if (this.state.currency === 'BTC') {
                let btcAddressUrl = (
                    <a
                        href={'https://blockchain.com/btc/address/' + this.state.altAddress }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        { this.state.altAddress }
                    </a>
                );
                summaryDataSource = summaryDataSource.concat([
                    {
                        key: 5,
                        field: 'Total price (BTC)',
                        value: this.state.registrationData.feeAlt
                    },
                    {
                        key: 6,
                        field: 'Price of Bitcoin',
                        value: this.state.registrationData.exchangeRate
                    },
                    {
                        key: 7,
                        field: 'Payment address',
                        value: btcAddressUrl
                    }
                ]);
            }

            if (this.state.registrationData.comment) {
                summaryDataSource.push(
                    {
                        key: 8,
                        field: 'Comments',
                        value: this.state.registrationData.comment
                    }
                );
            }

            this.state.registrationData.items.forEach(item => {
                detailDataSource.push({
                    key: item.name,
                    itemName: item.name,
                    quantity: item.quantity,
                    duration: item.duration,
                    subtotal: item.subtotal
                });
            })


        }

        let errorModalTitle = (
            <div style={{color: 'red'}}>Error</div>
        )

        return (
            <div>
                <Modal
                    title={errorModalTitle}
                    visible={this.state.showErrorDialog}
                    onOK={this.handleCloseErrorDialog}
                    onCancel={this.handleCloseErrorDialog}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.handleCloseErrorDialog}>OK</Button>
                    ]}
                >
                    <p>An error occurred:</p>
                    <p>{ this.state.errorText }</p>
                </Modal>

                <h2>{ header }</h2>

                <p>You can use this link to get back to this page:&nbsp;
                <a href={config.appUrl + '/registration-status/' + this.state.registrantKey}>{config.appUrl}/registration-status/{this.state.registrantKey}</a></p>

                <div>{ paymentArea }</div>

                <h3>Registration Summary</h3>

                <Table
                    columns={summaryColumns}
                    dataSource={summaryDataSource}
                    showHeader={false}
                    pagination={false}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                />

                <h3>Registration Detail</h3>

                <Table
                    columns={detailColumns}
                    dataSource={detailDataSource}
                    pagination={false}
                    style={{marginTop: '1em', marginBottom: '2em'}}
                />
            </div>
        )
    }
}

function PaymentButton(props) {
    const sessionId = props.sessionId;

    const handleClick = async (event) => {
        const stripe = await stripePromise;

        // Tell API that payment is being made.
        try {
            await axios.post(`${config.apiUrl}/api/v1/registrant/${props.registrantKey}/payment-initiated`);

            // Launch Stripe website to complete payment.
            const { error } = await stripe.redirectToCheckout({
                sessionId
            });

            if (error) {
                props.handlePaymentError(error)
            }
        } catch (axiosError) {
            props.handlePaymentError(axiosError)
        }

    };

    return (
        <Button type="primary" role="link" onClick={handleClick}>
            Pay ${props.paymentAmount}
        </Button>
    );
}

export default RegistrationStatus;
